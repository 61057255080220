import React, { useEffect } from 'react'
import Wrapper from '../UI/Wrapper'
import Main from '../components/Login/Main'
import Sidebar from '../components/Common/Sidebar'
import Left from '../components/Login/Left'
import Right from '../components/Login/Right'
import RightMobile from '../components/Common/RightMobile'
import ReactGA from "react-ga"

const Login = () => {

    //Google Analytics
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])

    return (
        <Wrapper>
            <div className="flex">
                <Sidebar notLoggedIn={true} />
                <Main>
                    {/* <Left /> */}
                    {/* <Right /> */}
                    <h1 className='text-[3rem] p-24'>We are working on it !</h1>
                    {/* <RightMobile /> */}
                </Main>
                <div className="fixed text-xs bottom-[40px] xs:hidden font-ubuntu xs:w-[200px] right-[40px]">
                    Please note that your data is protected and won’t be used anywhere else.
                </div>
            </div>
        </Wrapper>
    )
}

export default Login