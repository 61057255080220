import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import Container from '../../UI/landingPage/Container'
import { useInView } from "react-intersection-observer";

const ContactUs = () => {

  const { ref, inView } = useInView();

  useEffect(() => {
    // console.log("It's in view Contact", inView);
    AOS.init({ duration: 2000, once: true, })
  }, [inView])

  return (
    <section className='bg-[#0C1717] pb-2' ref={ref} id="contact">
      <Container>
        <div className='mb-3'>
          <span className='block text-[16px] text-[#F76411] font-medium relative after:content-[""] after:block after:w-[80px] after:h-[2px] after:bg-[#F76411] after:absolute after:-bottom-0 after:left-0 ' data-aos='fade-right'>Contact Us</span>
          <h2 className='text-[50px] lp-lg:text-[45px] lp-md:text-[30px] lp-sm:text-[26px] text-[#FDF5EE] leading-none font-constantia font-medium mt-3' data-aos='fade-right'>Get in touch with us</h2>
        </div>

        <div className='flex flex-row lp-lg:flex-col lp-md:flex-col lp-sm:flex-col items-center lp-md:items-start lp-sm:items-start gap-8 py-10 lp-md:py-5 lp-md:gap-5 lp-sm:py-5 lp-sm:gap-5'>
          <div className='flex flex-col gap-1 mr-8'>
            <a href="tel:+27 84 951 7553" className='text-[#FFDBB0] text-[1.85rem] lp-md:text-[1.25rem] lp-sm:text-[1.2rem]' data-aos='fade-right'>+27 84 951 7553</a>
            <a href="mailto:leshen@mohavi.tech" className='text-[#FFDBB0] text-[1.85rem] lp-md:text-[1.25rem] lp-sm:text-[1.2rem]' data-aos='fade-right'>leshen@mohavi.tech</a>
          </div>

          <div className='lp-lg:hidden lp-md:hidden lp-sm:hidden border border-[#172B2B] rounded-[5rem] px-10 py-4 min-w-[15rem]'>
            <h4 className='text-[#FFDBB0] text-[18px] underline mb-2' data-aos='fade-right'>Support</h4>
            <a href="tel:+27 84 951 7553" className='block text-[#FDF5EE] text-[14px]' data-aos='fade-right'>+27 84 951 7553
            </a>
            <a href="mailto:leshen@mohavi.tech" className='block text-[#FDF5EE] text-[14px]' data-aos='fade-right'>leshen@mohavi.tech</a>
          </div>

          <div className='lp-lg:hidden lp-md:hidden lp-sm:hidden border border-[#172B2B] rounded-[5rem] px-10 py-4 min-w-[15rem]'>
            <h4 className='text-[#FFDBB0] text-[18px] underline mb-2' data-aos='fade-right'>Work with Us</h4>
            {/* <a href="tel:+0123456789" className='block text-[#FDF5EE] text-[14px]' data-aos='fade-right'>+0123456789</a> */}
            <a href="mailto:leshen@mohavi.tech" className='block text-[#FDF5EE] text-[14px]' data-aos='fade-right'>leshen@mohavi.tech</a>
          </div>

          <div className='hidden lp-lg:flex lp-md:flex lp-md:flex-col lp-sm:flex lp-sm:flex-col gap-4'>
            <div className='border border-[#172B2B] rounded-[5rem] px-10 py-4 min-w-[15rem]'>
              <h4 className='text-[#FFDBB0] text-[18px] underline mb-2' data-aos='fade-right'>Support</h4>
              <a href="tel:+27 84 951 7553" className='block text-[#FDF5EE] text-[14px]' data-aos='fade-right'>+27 84 951 7553
              </a>
              <a href="mailto:leshen@mohavi.tech" className='block text-[#FDF5EE] text-[14px]' data-aos='fade-right'>leshen@mohavi.tech</a>
            </div>

            <div className='border border-[#172B2B] rounded-[5rem] px-10 py-4 min-w-[15rem]'>
              <h4 className='text-[#FFDBB0] text-[18px] underline mb-2' data-aos='fade-right'>Work with Us</h4>
              {/* <a href="tel:+0123456789" className='block text-[#FDF5EE] text-[14px]' data-aos='fade-right'>+0123456789</a> */}
              <a href="mailto:leshen@mohavi.tech" className='block text-[#FDF5EE] text-[14px]' data-aos='fade-right'>leshen@mohavi.tech</a>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default ContactUs