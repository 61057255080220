import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import { useState } from 'react';
import Container from '../../UI/landingPage/Container'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import emptyavatar from '../../assets/images/emptyavatar.webp'
import quotes from '../../assets/images/quotes.png'
// import quotes from '../../assets/images/quotes.svg'


const data = [
  {
    id: 1,
    text: `"Molz Platform have been super convenient & effective with their process since the beginning. They have proven to successfully increase overall morale and productivity consistently."`,
    user_name: `Megna Jain`,
    user_designation: `CEO at Dream a Dozen`,
    user_image: ``,
  },
  {
    id: 2,
    text: `"We have used their innovative and structured solutions for multiple clients and received great feedback on the experience. Their passion and research are evident in their solutions and execution. We highly recommend their services."`,
    user_name: `Ivy Emmanuel`,
    user_designation: `Director at Clockwork`,
    user_image: ``,
  },
  // {
  //   id: 3,
  //   text: `"Hapsmtihs have been super convenient & effective with their process since the beginning. They have proven to successfully increase overall morale and productivity consistently."`,
  //   user_name: ``,
  //   user_designation: ``,
  //   user_image: ``,
  // }
]
const Testimonials = () => {

  useEffect(() => {
    AOS.init({ duration: 2000, once: true, })
  }, [])

  const [swiperIndex, setSwiperIndex] = useState(1)

  return (
    <section className='bg-[#FDE9D8]' id="testimonials">
      <div className='relative z-[1]'>
        <div className='w-[50vw] lp-lg:w-[70vw] lp-md:w-[95vw] lp-sm:w-[95vw] lp-md:h-[100px] lp-sm:h-[100px] h-[150px] bg-[#fff] rounded-r-[100px]'></div>
        <div className='-mt-[7.375rem] lp-md:-mt-[5.375rem] lp-sm:-mt-[5.375rem]' data-aos='fade-right'>
          <Container>
            <span className='block text-[16px] text-[#F76411] font-medium relative after:content-[""] after:block after:w-[90px] after:h-[2px] after:bg-[#F76411] after:absolute after:-bottom-0 after:left-0 '>Testimonials</span>
            <h2 className='text-[50px] lp-lg:text-[45px] lp-md:text-[30px] lp-sm:text-[26px] text-[#0C1717] leading-none font-constantia font-medium mt-3'>What our clients think</h2>
          </Container>
        </div>
      </div>

      <Container>
        <div className='pt-28 pb-20 px-40 -mt-8 bg-[#172B2B] w-full rounded-[500px] relative bg-no-repeat bg-[left_calc(8%)_top_calc(21%)] lp-md:px-5 lp-md:rounded-[80px] lp-md:pt-20 lp-sm:px-5 lp-sm:rounded-[80px] lp-sm:pt-20'
          style={
            {
              backgroundImage: `url(${quotes})`,
              backgroundSize: '16%'
            }
          } data-aos='fade-left'>
          {/* Swiper code here */}
          < Swiper
            pagination={{
              type: "progressbar",
            }}
            autoplay={{
              delay: 5000,
              // disableOnInteraction: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            spaceBetween={10}
            slidesPerView={1}
            // onSlideChange={() => console.log('slide change')}
            onActiveIndexChange={(swiperCore) => { setSwiperIndex(swiperCore.activeIndex + 1); }}
            className="testimonialsSwiper"
          >
            {
              data.map((testimonial) => (
                <SwiperSlide>
                  <div key={testimonial.id} className='w-full'>
                    <p className='text-[20px] lp-md:text-[16px] lp-sm:text-[16px] text-[#FDF5EE] font-constantia mb-12 lp-md:mb-7 lp-sm:mb-7'>{testimonial.text}</p>
                    <div className='flex gap-3 items-center'>
                      <img src={emptyavatar} alt="user image" className='w-[50px] rounded-full lp-md:w-[40px] lp-sm:w-[40px]' />
                      <div className='flex flex-col gap-0'>
                        <h4 className='text-[#FDE9D8] text-[17px] lp-md:text-[15px] lp-sm:text-[15px] font-medium'>{testimonial.user_name}</h4>
                        <span className='text-[13px] text-[#FFC289] underline underline-offset-3'>{testimonial.user_designation}</span>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))
            }
          </Swiper >
          <div className='absolute bottom-[92px] right-[208px] lp-md:hidden lp-sm:hidden'>
            <p className='text-[#fff]'>{swiperIndex}/{data.length}</p>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Testimonials