import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import Container from '../../UI/landingPage/Container'
import Happiness_1 from '../../assets/images/hap1.svg'
import Happiness_2 from '../../assets/images/hap2.svg'
import Happiness_3 from '../../assets/images/hap3.svg'
import Happiness_4 from '../../assets/images/hap4.svg'
import Happiness_5 from '../../assets/images/hap5.svg'
import Happiness_line from '../../assets/images/hap-line.svg'

const stats = [{
  id: 1,
  icon: Happiness_1,
  text: `Easy and confidential access to personality tests curated by professionals.`
},
{
  id: 2,
  icon: Happiness_2,
  text: `Easy and unlimited access to curated contents for achieving personal goals.`
},
{
  id: 3,
  icon: Happiness_3,
  text: `Unlock your educational institutions very own academic counselling officer.`
},
{
  id: 4,
  icon: Happiness_4,
  text: `On-ground and digital workspace engagements and experiences.`
},
{
  id: 5,
  icon: Happiness_5,
  text: `Tangible Assessment scores to measure change over time.`
}]

const Happiness = () => {

  useEffect(() => {
    AOS.init({ duration: 2000, once: true, })
  }, [])

  return (
    <section className='py-5 lp-mlg:py-14 lp-md:mt-16' id='about'>
      <Container>
        <h2 data-aos='fade-right' className='text-[55px] lp-lg:text-[45px] lp-md:text-[30px] lp-sm:text-[28px] after:content-[""] after:w-[200px] after:h-[3px] after:bg-[#F76411] after:absolute after:-top-5 after:left-0 text-[#0C1717] leading-none font-constantia font-semibold mt-5 mb-8 lp-md:mb-3 lp-sm:mb-3 relative'>How we can help you assess your interest ?</h2>

        <div className='flex flex-wrap lp-md:block lp-sm:block'>
          {stats.map((stat) => (
            // :nth-child(-n+2)
            <div key={stat.id} className='grow-0 shrink-1 basis-1/3 p-8 first:grow first:shrink first:basis-1/2 second-child:grow second-child:shrink second-child:basis-1/2 lp-md:p-5 lp-md:last:pb-8 lp-sm:p-5 lp-sm:last:pb-8'>
              <div className='w-[70px] h-[80px] mx-auto mb-2 flex justify-center items-center lp-md:w-[60px] lp-md:h-[70px] lp-sm:w-[50px] lp-sm:h-[60px]' data-aos='fade-up'>
                <img src={stat.icon} alt="icon" className='w-full' />
              </div>
              <p className='text-center text-[#0C1717] text-[20px] lp-md:text-[16px] lp-sm:text-[16px]' data-aos='fade-up'>{stat.text}</p>
            </div>
          ))}
        </div>
      </Container>
    </section >
  )
}

export default Happiness