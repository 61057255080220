import React, { useEffect } from 'react'
// import logo from '../../assets/images/logo-livup.png'
import logo from '../../assets/images/Molz Logo/Molz Logo_3.svg'
import signup from '../../assets/images/signup.png'
import dashboard from '../../assets/images/dashboard.png'
import chat from '../../assets/images/chat.png'
import coachNotes from '../../assets/images/notes_sidebar.png'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import settings from '../../assets/images/settings.png'
import sos from '../../assets/images/sos.png'
import emptyavatar from '../../assets/images/emptyavatar.webp'
import { useDispatch } from 'react-redux'
import { therapistActions } from '../../services/therapistSlice'
import { useGetCategoriesQuery } from '../../services/categoryApi'
import { bubblesData } from '../Client/Welcome/bubbles data'
import { useGetCurrentUserQuery } from '../../services/authApi'
import { BASE_URL } from '../../keys'
import api from '../../services/api'
import { useState } from 'react'
import SosModal from '../Client/Chat/Right/Modals/SosModal'

const Sidebar = ({ notLoggedIn, findTherapist, chats }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [modalDisplay, setModalDisplay] = useState(false);
    const [sectionWidth, setSectionWidth] = useState('100px')
    const [logoBgWidth, setLogoBgWidth] = useState('120px')

    const [isSubscribed, setIsSubscribed] = useState(false)
    const [visibility, setVisibility] = useState('invisible')

    const [token, setToken] = useState(null)
    const [data, setData] = useState(null)
    const [user, setUser] = useState(null)
    const [isSuccess, setIsSuccess] = useState(null)

    useEffect(() => {
        setToken(localStorage.getItem('livtoken'))
    }, [localStorage.getItem('livtoken')])


    // const handleChange = event => {
    //     if (event.target.checked) {
    //         setSectionWidth('250px')
    //         setLogoBgWidth('250px')
    //         setVisibility('')
    //     } else {
    //         setSectionWidth('100px')
    //         setLogoBgWidth('120px')
    //         setVisibility('invisible')
    //     }
    //     setIsSubscribed(current => !current);
    // };

    // const { data, isSuccess } = useGetCategoriesQuery(token)
    // const { data: user, isLoading } = useGetCurrentUserQuery()
    // console.log('user?.data?.user_type', user?.data?.user_type)

    useEffect(() => {
        if (token) {
            api.get(`client/list-categories/`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("livtoken")}`,
                }
            }).then((res) => {
                setData(res.data)
                setIsSuccess(true)
            })
        }
    }, [token])

    useEffect(() => {
        if (token) {
            api.get(`common/get-profile-detail/`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("livtoken")}`,
                }
            }).then((res) => {
                setUser(res.data)
            })
        }
    }, [token])

    // Just changed Vivek's on toggle button code to onHover code on sidebar
    const sidebarOn = () => {
        setSectionWidth('250px')
        setLogoBgWidth('250px')
        setVisibility('')
        setIsSubscribed(current => !current);
    }
    const sidebarOff = () => {
        setSectionWidth('100px')
        setLogoBgWidth('120px')
        setVisibility('invisible')
        setIsSubscribed(current => !current);
    }

    return (
        <section className={`flex w-[${sectionWidth}] xs:w-0 xs:overflow-hidden relative flex-col pt-[40px] transition-all duration-300 ease-in-out`} onMouseOver={sidebarOn} onMouseLeave={sidebarOff}>
            {/* <div className="flex w-[100px] justify-center">
                <label
                    htmlFor="checked-toggle"
                    className="inline-flex relative items-center cursor-pointer"
                >
                    <input
                        type="checkbox"
                        id="checked-toggle"
                        className="sr-only peer"
                        onChange={handleChange}
                    />
                    <div className="w-11 h-6 bg-gray-200 rounded-full peer  dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#f76411]"></div>
                </label>
            </div> */}
            
            {notLoggedIn && ( // when user is not logged in
                <>
                    <nav className="mt-[150px]">
                        <Link to="/home" className={`h-[50px] cursor-pointer bg-[#172B2B] flex flex-wrap top-[100px] absolute w-[${logoBgWidth}] rounded-r-[20px]`}>
                            <img
                                className="w-[60px] h-[90%] object-contain mt-[3px] ml-8 object-left"
                                src={logo} alt="Hapsmiths logo"
                            />
                            {/* <span className={`mt-3 ${visibility} text-[#fdf5ee] font-semibold`}>Molz</span> */}
                        </Link>
                    </nav>
                </>
            )}

            {!notLoggedIn && ( // when user is logged in
                <>
                    <nav className="flex flex-col mt-[120px] gap-4">
                        {user?.data?.user_type === 'client' && (
                            <>
                                <NavLink
                                    to="/welcome"
                                    className={`h-[50px] w-[${logoBgWidth}] flex flex-wrap cursor-pointer bg-[#172B2B] top-[100px] absolute rounded-r-[20px]`}
                                >
                                    <img
                                        className="w-[60px] h-[90%] object-contain mt-[3px] ml-8 object-left"
                                        src={logo}
                                        alt="Hapsmiths logo"
                                    />
                                    <span className={`mt-3 ${visibility} text-[#fdf5ee] font-semibold`}>Hapsmiths</span>
                                </NavLink>
                                {findTherapist && (
                                    <div className="flex flex-col pl-2 gap-4 ml-8">
                                        {isSuccess &&
                                            data?.data?.map((item, index) => (
                                                <div
                                                    key={item.id}
                                                    onClick={() => {
                                                        if (item.is_active === 1) {
                                                            navigate(
                                                                '/findtherapist/' +
                                                                item.name +
                                                                '?' +
                                                                item.id
                                                            )
                                                            dispatch(
                                                                therapistActions.setBG(
                                                                    // `bg-[${bubblesData[index].color}]`
                                                                    `${item.color_code}`
                                                                )
                                                            )
                                                            dispatch(
                                                                therapistActions.setCategoryIcon(
                                                                    `${item.logo}`
                                                                )
                                                            )
                                                            dispatch(
                                                                therapistActions.setCategoryName(
                                                                    `${item.name}`
                                                                )
                                                            )
                                                            //Maintain this state as empty apart from UpComingSessions
                                                            localStorage.removeItem('Booking_id')
                                                            dispatch(therapistActions.setCoachIdBN(''))
                                                            dispatch(therapistActions.setBtnClick('no'))
                                                        }
                                                    }}
                                                    className={`h-8 w-8 rounded-full relative ${item.is_active === 1 ? 'cursor-pointer' : 'cursor-no-drop'}`}
                                                    style={{
                                                        backgroundColor: `${item.color_code}`,
                                                    }}
                                                // bg-[${bubblesData[index].color}]
                                                >
                                                    <span className={`ml-12 mt-[6px] text-[#fdf5ee] text-sm whitespace-nowrap inline-block ${visibility}`}>{item.name}</span>
                                                </div>
                                            ))}
                                    </div>
                                )}
                                <NavLink
                                    to="/chat"
                                    className={({ isActive }) =>
                                        isActive
                                            ? 'h-[50px] rounded-r-[20px] cursor-pointer relative after:absolute after:h-[50px] after:w-1 after:bg-[#f76411] after:right-0 after:top-[0px] after:rounded-md flex'
                                            : 'h-[50px] rounded-r-[20px] cursor-pointer relative flex'
                                    }
                                >
                                    <img
                                        className="h-[60%] object-contain mt-2 ml-10"
                                        src={chat}
                                        alt="Chat"
                                    />
                                    <span className={`ml-5 mt-3 text-[#fdf5ee] ${visibility}`}>Chats</span>
                                </NavLink>
                            </>
                        )}

                        {user?.data?.user_type === 'coach' && (
                            <>
                                <NavLink
                                    to="/coach-welcome"
                                    className={`h-[50px] w-[${logoBgWidth}] flex flex-wrap cursor-pointer bg-[#172B2B] top-[100px] absolute rounded-r-[20px]`}
                                >
                                    <img
                                        className="w-[60px] h-[90%] object-contain mt-[3px] ml-8 object-left"
                                        src={logo}
                                        alt="Hapsmiths logo"
                                    />
                                    <span className={`mt-3 ${visibility} text-[#fdf5ee] font-semibold`}>Hapsmiths</span>
                                </NavLink>

                                <NavLink
                                    to="/coach-chat"
                                    className={({ isActive }) =>
                                        isActive
                                            ? 'h-[50px] rounded-r-[20px] cursor-pointer relative after:absolute after:h-[50px] after:w-1 after:bg-[#f76411] after:right-0 after:top-[0px] after:rounded-md flex'
                                            : 'h-[50px] rounded-r-[20px] cursor-pointer relative flex'
                                    }
                                >
                                    <img
                                        className="h-[60%] object-contain mt-2 ml-10"
                                        src={chat}
                                        alt="Chat"
                                    />
                                    <span className={`ml-6 mt-3 text-[#fdf5ee] ${visibility}`}>Chats</span>
                                </NavLink>

                                <NavLink
                                    to="/coach-notes"
                                    className={({ isActive }) =>
                                        isActive
                                            ? 'h-[50px] rounded-r-[20px] cursor-pointer relative after:absolute after:h-[50px] after:w-1 after:bg-[#f76411] after:right-0 after:top-[0px] after:rounded-md flex'
                                            : 'h-[50px] rounded-r-[20px] cursor-pointer relative flex'
                                    }
                                >
                                    <img
                                        className="h-[60%] object-contain mt-2 ml-10"
                                        src={coachNotes}
                                        alt="Notes"
                                    />
                                    <span className={`ml-7 mt-3 text-[#fdf5ee] ${visibility}`}>Notes</span>
                                </NavLink>
                            </>
                        )}
                    </nav>

                    <nav className="flex flex-col ml-4 absolute gap-2 bottom-0">
                        {!findTherapist && !chats && (
                            <>
                                <NavLink
                                    to="/settings"
                                    className={({ isActive }) =>
                                        isActive
                                            ? 'h-[50px] rounded-r-[20px] cursor-pointer relative flex'
                                            : 'h-[50px] rounded-r-[20px] cursor-pointer relative flex'
                                    }
                                >
                                    <img
                                        className="h-[60%] object-contain mt-2 ml-5"
                                        src={settings}
                                        alt="settings"
                                    />
                                    <span className={`ml-7 mt-3 text-[#fdf5ee] ${visibility}`}>Settings</span>
                                </NavLink>
                                <NavLink
                                    to=""
                                    onClick={() => setModalDisplay(true)}
                                    className={({ isActive }) =>
                                        isActive
                                            ? 'h-[50px] rounded-r-[20px] cursor-pointer relative flex'
                                            : 'h-[50px] rounded-r-[20px] cursor-pointer relative'
                                    }
                                >
                                    <img
                                        className="h-[60%] object-contain mt-2 ml-[1.2rem]"
                                        src={sos}
                                        alt="sos"
                                    />
                                    <span className={`ml-[1.6rem] mt-3 text-[#fdf5ee] ${visibility}`}>SOS</span>
                                </NavLink>

                                {modalDisplay &&
                                    <SosModal onCloseModal={() => setModalDisplay(false)} />
                                }
                            </>
                        )}

                        <div className={'h-[50px] rounded-r-[20px] ml-4 relative cursor-pointer'}>
                            {user?.data?.user_type === 'client' && (
                                <Link to="/profile" className='flex'>
                                    <img
                                        className={`h-[35px] w-[35px] rounded-full flex mt-2 `}
                                        src={user?.data?.profile_pic ? BASE_URL + user?.data?.profile_pic : emptyavatar}
                                        title={user?.data?.name}
                                    />
                                    <span className={`ml-6 mt-3 text-[#fdf5ee] ${visibility}`}>Profile</span>
                                </Link>
                            )}
                            {user?.data?.user_type === 'coach' && (
                                <Link to="/coach-profile" className='flex'>
                                    <img
                                        className={`h-[35px] w-[35px] rounded-full flex mt-2 `}
                                        src={user?.data?.profile_pic ? BASE_URL + user?.data?.profile_pic : emptyavatar}
                                        title={user?.data?.name}
                                    />
                                    <span className={`ml-6 mt-3 text-[#fdf5ee] ${visibility}`}>Profile</span>
                                </Link>
                            )}
                        </div>
                    </nav>
                </>
            )}

        </section>
    )
}

export default Sidebar
